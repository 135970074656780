<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-form-model :model="form" position="horizontal" :label-col="{ span: 2 }" :wrapper-col="{ span: 10 }"
        class="padding-lr">
        <a-form-model-item label="上传图片" prop="dressUpIcon">
          <file-upload type="image" :defaultList="form.imgArr" :count="form.imgCount"
            @input="getImg($event, 'img')"></file-upload>
        </a-form-model-item>
        <a-form-model-item label="上传视频" prop="dressUpIcon">
          <file-upload type="video" :defaultList="form.videoArr" :count="form.videoCount"
            @input="getImg($event, 'video')"></file-upload>
        </a-form-model-item>
        <a-form-model-item label="展示图片" prop="dressUpIcon">
          <file-upload :disabled="true" :defaultList="form.imgArr" :count="form.imgCount"
            @input="getImg($event, 'img')"></file-upload>
        </a-form-model-item>
        <a-form-model-item label="展示视频" prop="dressUpIcon">
          <div class="imgbox margin-left" @click="handlePreview">
            <div class="videomask">
              <a-icon class="icon" type="play-circle" />
            </div>
            <img :src="ossHost + form.video + '?x-oss-process=video/snapshot,t_0,f_jpg'" alt="" class="response"
              style="width: 100px;">
          </div>
        </a-form-model-item>
        <!-- 视频预览播放 -->
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
          <!-- <img alt="example" style="width: 100%" :src="previewImage" /> -->
          <video style="width: 100%" :src="previewImage" ref="myVideo"
            :poster="ossHost + form.video + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
        </a-modal>
      </a-form-model>
    </a-card>
  </page-header-wrapper>
</template>
<script>
import { guid } from '@/utils/ruoyi'

export default {
  data() {
    return {
      ossHost: process.env.VUE_APP_OSS_HOST,
      form: {
        img: '',
        imgCount: 1,
        imgArr: [],
        video: '',
        videoCount: 1,
        videoArr: []
      },
      previewVisible: false,
      previewImage: '',
    }
  },
  created() {
    this.form.imgCount = 2
    // this.form.img = '2023/08/19/9a0f7629-5bbe-489c-9af3-a85c235bdf2f.png'
    this.form.img = '2023/08/19/9a0f7629-5bbe-489c-9af3-a85c235bdf2f.png,2023/08/19/9a0f7629-5bbe-489c-9af3-a85c235bdf2f.png'
    this.form.imgArr = this.setImgData('img', this.form.img)

    this.form.videoCount = 1
    this.form.video = '2023/08/15/180313109-compose.mp4'
    this.form.videoArr = this.setImgData('video', this.form.video)
    // console.log('form', this.form)
  },
  methods: {
    /******************** 上传图片数据处理 start ********************/
    /**前端 需要将图片格式处理成数组，方便数据回显 */
    setImgData(field, img) {
      let fieldImgArr = []
      if (img.indexOf(",") === -1) {
        // 单图
        if (this.form[`${field}Count`] === 1) {
          if (img) {
            fieldImgArr.push({
              uid: guid(8, 10),
              name: img,
              status: 'done',
              halfUrl: img,
              url: this.ossHost + img,
              path: this.ossHost + img
            })
          }
        }
      } else {
        // 多图
        if (this.form[`${field}Count`] > 1) {
          let imgArr = img.split(",")
          if (imgArr.length) {
            imgArr.map(item => {
              fieldImgArr.push({
                uid: guid(8, 10),
                name: img,
                status: 'done',
                halfUrl: img,
                url: this.ossHost + item,
                path: this.ossHost + item
              })
            })
          }
        }
      }
      return fieldImgArr
    },
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      // console.log('info111111', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].halfUrl
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.halfUrl }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    async handlePreview(file) {
      this.previewImage = this.ossHost + this.form.video || this.ossHost + file.video;
      this.previewVisible = true;
      this.$nextTick(() => {
        this.$refs.myVideo.play()
      })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    /******************** 上传图片数据处理 end   ********************/
  }
}
</script>
<style lang="less">
.imgbox {
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;

  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.videomask {
  position: absolute;
  z-index: 10;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  line-height: 100px;
  background: rgba(0, 0, 0, .3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 26px !important;
    color: #fff !important;
  }
}
</style>